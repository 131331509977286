<template>
	<div class="notification-container">
		<div
			v-if="notification"
			class="notification"
			:class="[type, `size-${size}`, orientation, { dark, actionable }]"
		>
			<div style="display: flex; align-items: center">
				<TnIcon
					v-if="!hideIcon"
					:name="icon"
					class="notification-icon"
					:class="`icon-${type}`"
					:size="iconSize"
					:aria-label="`Notification type: ${type}`"
				/>
			</div>
			<TnButton
				v-if="closable"
				class="close-button"
				icon-only="remove"
				size="xs"
				tertiary
				:dark="dark"
				@click="clearPopup"
				@keyup.enter="clearPopup"
				@keyup.space="clearPopup"
				aria-label="Lukk"
			/>
			<div class="notification-content-wrapper">
				<div class="notification-content">
					<span
						v-if="title"
						class="notification-content-title"
						>{{ title }}</span
					>
					<div
						class="notification-content"
						v-if="$slots['default']"
					>
						<slot />
					</div>
				</div>
				<div
					v-if="$slots['actions']"
					class="actions"
				>
					<!-- @slot Slot for actions, placement bottom lower-right or bottom left -->
					<slot name="actions" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";
import types from "./definitions/types";
import orientations from "./definitions/orientations";

/**
 * Notification is used to give general user feedback or feedback based on user-action
 * @displayName TnNotification
 */
export default defineComponent({
	name: "TnNotification",

	props: {
		/**
		 * The style of the notification
		 * @values informational, neutral, success, warning, error, attention
		 */
		type: {
			type: String,
			default: "informational",
			validator: function (value) {
				if (value) return types.indexOf(value.toLowerCase()) !== -1;
				return true;
			},
		},
		/**
		 * The content of the notification
		 */
		title: {
			type: String,
			default: "",
		},
		/**
		 * Dark
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Closable
		 */
		closable: {
			type: Boolean,
			default: false,
		},
		/**
		 * Size
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase()) || "m";
			},
		},
		/**
		 * Orientation
		 * @values horizontal, horizontal-responsive, vertical, vertical-responsive
		 */
		orientation: {
			type: String,
			default: "horizontal-responsive",
			validator: function (value) {
				return orientations.includes(value.toLowerCase());
			},
		},
		/**
		 * Hides the UI icon
		 */
		hideIcon: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		/**
		 * Event fired when Notification is closed
		 */
		"close",
	],

	data() {
		return {
			popup: true,
			actionable: false,
		};
	},

	computed: {
		icon() {
			switch (this.type.toLowerCase()) {
				case "informational":
					return "info-circle-filled";
				case "neutral":
					return "info-circle-filled";
				case "success":
					return "success-circle-filled";
				case "warning":
					return "warning-circle-filled";
				case "attention":
					return "attention-circle-filled";
				case "error":
					return "critical-filled";
				default:
					return "info-circle-filled";
			}
		},
		iconSize() {
			if (this.size === "s") return "l";
			else if (this.size === "m") return "xl";
			else if (this.size === "l") return "2xl";
		},
		notification() {
			return this.popup;
		},
	},

	mounted() {
		if (this.$slots?.["actions"]) {
			this.actionable = true;
		}
		if (this.$slots?.["default"]) {
			this.titleOnly = true;
		}
	},

	methods: {
		clearPopup() {
			this.$emit("close");
			this.popup = false;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/scss/mixins" as typoMixins;
@use "@/assets/global-style/scss/mixins/all" as mixins;
@use "@/assets/global-style/scss/mixins/shadow" as shadow;

// Local mixins due to limitations of css variables with @media and @container queries.
@mixin horizontal-responsive {
	flex-direction: column;

	.notification-content {
		&-wrapper {
			flex-direction: column !important;
			align-items: flex-start !important;
		}
	}

	.actions {
		align-self: flex-start;
	}
}

@mixin vertical-responsive {
	flex-direction: row;

	.notification-content {
		&-wrapper {
			flex-direction: row;
			align-items: flex-end;
		}
	}

	.actions {
		align-self: flex-end;
	}
}

.notification {
	display: flex;
	align-items: flex-start;
	overflow: hidden;
	border-radius: var(--border-radius);
	position: relative;
	width: 100%;
	box-sizing: border-box;
	padding: var(--padding);
	gap: var(--gap);
	background-color: var(--background-color);
	color: var(--foreground-color);

	&-container {
		container-type: inline-size;
		container-name: notification-container;
		width: 100%;
	}

	&-content {
		display: flex;
		flex-direction: column;
		gap: var(--content-gap);

		&-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: stretch;
			gap: variables.$spacing-m;
		}
	}

	.close-button {
		position: absolute;
		top: var(--padding);
		right: var(--padding);
		transform: translate(50%, -25%);
	}

	.actions {
		flex-grow: 0;
		flex-shrink: 0;
	}

	// &.prominent {
	//   padding-left: calc(var(--padding) - var(--border-radius));
	//   box-shadow: inset 0 0 0 1px var(--icon-background-color);
	//   border-left: var(--border-radius) solid var(--icon-background-color);
	// }

	// Orientation and responsiveness
	&.horizontal,
	&.horizontal-responsive {
		flex-direction: row;

		.actions {
			align-self: flex-end;
		}

		.notification-content {
			&-wrapper {
				flex-direction: row;
			}
		}
	}

	&.vertical,
	&.vertical-responsive {
		flex-direction: column;

		.actions {
			align-self: flex-start;
		}

		.notification-content {
			&-wrapper {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	&-icon {
		flex-shrink: 0;

		&:not(.icon-error) {
			:deep() {
				.background {
					fill: var(--icon-background-color);
				}

				* {
					fill: var(--icon-foreground-color);
				}
			}
		}
	}

	// Sizes and scaling
	&.size {
		&-s {
			--padding: #{variables.$spacing-l};
			--border-radius: #{variables.$border-radius-s};
			--gap: #{variables.$spacing-xs} #{variables.$spacing-s};
			--content-gap: #{variables.$spacing-2xs};

			@include typoMixins.font-text-s;

			.notification-content-title {
				@include typoMixins.font-text-bold-m;

				@media screen and (max-width: variables.$size-screen-phone) {
					@include typoMixins.font-text-bold-s;
				}
			}

			&.horizontal-responsive {
				&:not(.actionable) {
					@container notification-container (inline-size <= 400px) {
						@include horizontal-responsive;
					}
				}

				&.actionable {
					@container notification-container (inline-size <= 540px) {
						@include horizontal-responsive;
					}
				}
			}

			&.vertical {
				--padding: #{variables.$spacing-l};

				&-responsive {
					&:not(.actionable) {
						@container notification-container (inline-size >= 400px ) {
							@include vertical-responsive;
						}
					}

					&.actionable {
						@container notification-container (inline-size >= 600px ) {
							@include vertical-responsive;
						}
					}
				}
			}

			@media screen and (max-width: variables.$size-screen-phone) {
				@include typoMixins.font-text-xs;
			}
		}

		&-m {
			--padding: #{variables.$spacing-xl};
			--border-radius: #{variables.$border-radius-m};
			--gap: #{variables.$spacing-s} #{variables.$spacing-s};
			--content-gap: #{variables.$spacing-xs};

			@include typoMixins.font-text-m;

			.notification-content-title {
				@include typoMixins.font-text-bold-l;

				@media screen and (max-width: variables.$size-screen-phone) {
					@include typoMixins.font-text-bold-m;
				}
			}

			&.horizontal-responsive {
				&:not(.actionable) {
					@container notification-container (inline-size <= 500px) {
						@include horizontal-responsive;
					}
				}

				&.actionable {
					@container notification-container (inline-size <= 650px) {
						@include horizontal-responsive;
					}
				}
			}

			&.vertical {
				--padding: #{variables.$spacing-xl};

				&-responsive {
					&:not(.actionable) {
						@container notification-container (inline-size >= 500px ) {
							@include vertical-responsive;
						}
					}

					&.actionable {
						@container notification-container (inline-size >= 700px ) {
							@include vertical-responsive;
						}
					}
				}
			}

			@media screen and (max-width: variables.$size-screen-phone) {
				@include typoMixins.font-text-s;
			}
		}

		&-l {
			--padding: #{variables.$spacing-2xl};
			--border-radius: #{variables.$border-radius-l};
			--gap: #{variables.$spacing-m} #{variables.$spacing-m};
			--content-gap: #{variables.$spacing-s};

			@include typoMixins.font-text-l;

			.notification-content-title {
				@include typoMixins.font-text-bold-xl;

				@media screen and (max-width: variables.$size-screen-phone) {
					@include typoMixins.font-text-bold-l;
				}
			}

			&.horizontal-responsive {
				&:not(.actionable) {
					@container notification-container (inline-size <= 600px) {
						@include horizontal-responsive;
					}
				}

				&.actionable {
					@container notification-container (inline-size <= 800px) {
						@include horizontal-responsive;
					}
				}
			}

			&.vertical {
				--padding: #{variables.$spacing-2xl};

				&-responsive {
					&:not(.actionable) {
						@container notification-container (inline-size >= 800px ) {
							@include vertical-responsive;
						}
					}

					&.actionable {
						@container notification-container (inline-size >= 900px ) {
							@include vertical-responsive;
						}
					}
				}
			}

			@media screen and (max-width: variables.$size-screen-phone) {
				@include typoMixins.font-text-s;
			}
		}
	}

	// Color and Appearance
	&.informational {
		--background-color: #{variables.$color-information-200-tint};
		--foreground-color: #{variables.$color-information-800-shade};
		--icon-background-color: #{variables.$color-information-500-core};
		--icon-foreground-color: #{variables.$color-neutrals-white};

		&.dark {
			--background-color: #{variables.$color-information-800-shade};
			--foreground-color: #{variables.$color-information-50-tint};
			--icon-background-color: #2954ff; // TODO
			--icon-foreground-color: #{variables.$color-neutrals-white};
		}
	}

	&.info-gray,
	&.neutral {
		--background-color: #{variables.$color-neutrals-100-tint};
		--foreground-color: #{variables.$color-neutrals-900-shade};
		--icon-background-color: #{variables.$color-neutrals-700-shade};
		--icon-foreground-color: #{variables.$color-neutrals-white};

		&.dark {
			--background-color: #{variables.$color-neutrals-900-shade};
			--foreground-color: #{variables.$color-neutrals-50-tint};
			--icon-background-color: #{variables.$color-neutrals-50-tint};
			--icon-foreground-color: #{variables.$color-neutrals-black};
		}
	}

	&.success {
		--background-color: #{variables.$color-success-200-tint};
		--foreground-color: #{variables.$color-success-800-shade};
		--icon-background-color: #{variables.$color-success-500-core};
		--icon-foreground-color: #{variables.$color-neutrals-white};

		&.dark {
			--background-color: #{variables.$color-success-800-shade};
			--foreground-color: #{variables.$color-success-50-tint};
			--icon-background-color: #{variables.$color-success-500-core};
			--icon-foreground-color: #{variables.$color-neutrals-white};
		}
	}

	&.warning {
		--background-color: #{variables.$color-warning-200-tint};
		--foreground-color: #{variables.$color-warning-800-shade};
		--icon-background-color: #{variables.$color-warning-500-core};
		--icon-foreground-color: #{variables.$color-neutrals-white};

		&.dark {
			--background-color: #{variables.$color-warning-800-shade};
			--foreground-color: #{variables.$color-warning-200-tint};
			--icon-background-color: #{variables.$color-warning-500-core};
			--icon-foreground-color: #{variables.$color-neutrals-white};
		}
	}

	&.attention {
		--background-color: #{variables.$color-attention-200-tint};
		--foreground-color: #{variables.$color-attention-800-shade};
		--icon-background-color: #{variables.$color-attention-500-core};
		--icon-foreground-color: #{variables.$color-neutrals-black};

		&.dark {
			--background-color: #{variables.$color-attention-800-shade};
			--foreground-color: #{variables.$color-attention-200-tint};
			--icon-background-color: #{variables.$color-attention-500-core};
			--icon-foreground-color: #{variables.$color-neutrals-black};
		}
	}

	&.error {
		--background-color: #{variables.$color-critical-200-tint};
		--foreground-color: #{variables.$color-critical-800-shade};
		--icon-background-color: #{variables.$color-critical-500-core};
		--icon-foreground-color: #{variables.$color-neutrals-white};

		&.dark {
			--background-color: #{variables.$color-critical-800-shade};
			--foreground-color: #{variables.$color-critical-200-tint};
			--icon-background-color: #{variables.$color-critical-500-core};
			--icon-foreground-color: #{variables.$color-neutrals-white};
		}
	}
}
</style>
